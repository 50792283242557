// src/pages/CalendarPage.jsx
import React, { useMemo } from 'react';
import { useBoards } from '../contexts/BoardContext';
import { Calendar, Clock, Tag, Users } from 'lucide-react';

const CalendarPage = () => {
  const { boards, currentBoard } = useBoards();
  const currentBoardData = currentBoard ? boards[currentBoard] : null;

  // Get all tasks with due dates for the current board
  const tasksWithDueDates = useMemo(() => {
    if (!currentBoardData) return [];

    const tasks = [];
    Object.entries(currentBoardData.columns).forEach(([columnId, column]) => {
      column.items
        .filter(task => task.dueDate)
        .forEach(task => {
          tasks.push({
            ...task,
            columnId,
            columnTitle: column.title
          });
        });
    });

    return tasks.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
  }, [currentBoardData]);

  // Group tasks by due date
  const tasksByDate = useMemo(() => {
    const grouped = {};
    tasksWithDueDates.forEach(task => {
      const date = task.dueDate.split('T')[0];
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(task);
    });
    return grouped;
  }, [tasksWithDueDates]);

  // Helper function to determine if a date is past due
  const isPastDue = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return new Date(date) < today;
  };

  if (!currentBoardData) {
    return (
      <div className="p-6">
        <div className="text-center py-8">
          <h2 className="text-xl font-semibold text-gray-600">No board selected</h2>
          <p className="text-gray-500 mt-2">Please select a board to view the calendar</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Task Calendar</h1>

      {Object.keys(tasksByDate).length === 0 ? (
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-8 text-center">
          <Calendar className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-semibold text-gray-600">No Tasks Scheduled</h3>
          <p className="text-gray-500 mt-1">No tasks with due dates have been created yet.</p>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="divide-y divide-gray-200">
            {Object.entries(tasksByDate).map(([date, tasks]) => (
              <div key={date} className="p-4">
                <div className="flex items-center gap-2 mb-3">
                  <Calendar className={`w-5 h-5 ${
                    isPastDue(date) ? 'text-red-500' : 'text-blue-500'
                  }`} />
                  <h2 className={`font-semibold ${
                    isPastDue(date) ? 'text-red-600' : 'text-gray-900'
                  }`}>
                    {new Date(date).toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                    {isPastDue(date) && ' (Past Due)'}
                  </h2>
                </div>

                <div className="space-y-3 ml-7">
                  {tasks.map(task => (
                    <div
                      key={task.id}
                      className="bg-gray-50 p-4 rounded-lg border border-gray-200"
                    >
                      <div className="flex justify-between items-start">
                        <div className="flex-1">
                          <h3 className="font-medium text-gray-900">{task.title}</h3>
                          {task.description && (
                            <p className="text-sm text-gray-600 mt-1">{task.description}</p>
                          )}
                        </div>
                        <div className={`px-2 py-1 rounded-full text-xs font-medium ml-4
                          ${task.priority === 'high' ? 'bg-red-100 text-red-800' :
                            task.priority === 'medium' ? 'bg-yellow-100 text-yellow-800' :
                            'bg-green-100 text-green-800'}`}>
                          {task.priority}
                        </div>
                      </div>

                      <div className="mt-3 flex flex-wrap items-center gap-4 text-sm text-gray-500">
                        <div className="flex items-center gap-1">
                          <Clock className="w-4 h-4" />
                          <span>{task.columnTitle}</span>
                        </div>
                        
                        {task.assignees && task.assignees.length > 0 && (
                          <div className="flex items-center gap-1">
                            <Users className="w-4 h-4" />
                            <span>{task.assignees.map(a => a.split('@')[0]).join(', ')}</span>
                          </div>
                        )}

                        {task.tags && task.tags.length > 0 && (
                          <div className="flex flex-wrap items-center gap-2">
                            {task.tags.map(tag => (
                              <div key={tag} className="flex items-center gap-1 bg-gray-100 px-2 py-0.5 rounded-full">
                                <Tag className="w-3 h-3" />
                                <span>{tag}</span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarPage;