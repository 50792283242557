// src/pages/AnalyticsPage.jsx
import React from 'react';
import { BarChart2, TrendingUp, Clock, CheckCircle } from 'lucide-react';
import { useBoards } from '../contexts/BoardContext';

const AnalyticsPage = () => {
  const { boards, currentBoard } = useBoards();
  const currentBoardData = currentBoard ? boards[currentBoard] : null;

  const getTaskStats = () => {
    if (!currentBoardData) return { total: 0, completed: 0, inProgress: 0 };

    const { columns } = currentBoardData;
    return {
      total: Object.values(columns).reduce((acc, col) => acc + col.items.length, 0),
      completed: columns.done?.items.length || 0,
      inProgress: columns.inProgress?.items.length || 0
    };
  };

  const stats = getTaskStats();
  const completionRate = stats.total > 0 ? ((stats.completed / stats.total) * 100).toFixed(1) : 0;

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Analytics Dashboard</h1>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-500 text-sm">Total Tasks</p>
              <h3 className="text-2xl font-bold mt-1">{stats.total}</h3>
            </div>
            <div className="bg-blue-50 p-3 rounded-lg">
              <BarChart2 className="w-6 h-6 text-blue-500" />
            </div>
          </div>
          <div className="mt-4 text-sm text-gray-600">
            All tasks across all columns
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-500 text-sm">Completion Rate</p>
              <h3 className="text-2xl font-bold mt-1">{completionRate}%</h3>
            </div>
            <div className="bg-green-50 p-3 rounded-lg">
              <TrendingUp className="w-6 h-6 text-green-500" />
            </div>
          </div>
          <div className="mt-4 text-sm text-gray-600">
            Tasks completed vs total tasks
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-500 text-sm">In Progress</p>
              <h3 className="text-2xl font-bold mt-1">{stats.inProgress}</h3>
            </div>
            <div className="bg-yellow-50 p-3 rounded-lg">
              <Clock className="w-6 h-6 text-yellow-500" />
            </div>
          </div>
          <div className="mt-4 text-sm text-gray-600">
            Tasks currently being worked on
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-500 text-sm">Completed</p>
              <h3 className="text-2xl font-bold mt-1">{stats.completed}</h3>
            </div>
            <div className="bg-indigo-50 p-3 rounded-lg">
              <CheckCircle className="w-6 h-6 text-indigo-500" />
            </div>
          </div>
          <div className="mt-4 text-sm text-gray-600">
            Total completed tasks
          </div>
        </div>
      </div>

      {/* Coming soon message */}
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
        <h2 className="text-lg font-semibold mb-4">More Analytics Coming Soon</h2>
        <p className="text-gray-600">
          We're working on adding more detailed analytics including:
        </p>
        <ul className="mt-2 space-y-2 text-gray-600">
          <li>• Task completion trends over time</li>
          <li>• Member performance metrics</li>
          <li>• Time spent in each column</li>
          <li>• Priority distribution analysis</li>
        </ul>
      </div>
    </div>
  );
};

export default AnalyticsPage;