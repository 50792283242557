// src/hooks/useKanban.js
import { useState, useCallback, useEffect, useRef } from 'react';
import { useBoards } from '../contexts/BoardContext';
import { useActivityLog } from '../contexts/ActivityLogContext';

const DEFAULT_COLUMNS = {
  backlog: {
    title: 'Backlog',
    items: [],
    limit: 15
  },
  inProgress: {
    title: 'In Progress',
    items: [],
    limit: 8
  },
  review: {
    title: 'Review',
    items: [],
    limit: 8
  },
  done: {
    title: 'Done',
    items: [],
    limit: 15
  }
};

const useKanban = (initialColumns = DEFAULT_COLUMNS) => {
  const [columns, setColumns] = useState(initialColumns || DEFAULT_COLUMNS);
  const [draggedItem, setDraggedItem] = useState(null);
  const [error, setError] = useState('');
  const { currentBoard, updateBoardColumns } = useBoards();
  const { logActivity } = useActivityLog();
  
  // Use a ref to track if the columns are being updated from props
  const isUpdatingFromProps = useRef(false);

  // Update columns when initialColumns changes
  useEffect(() => {
    if (initialColumns && Object.keys(initialColumns).length > 0) {
      isUpdatingFromProps.current = true;
      setColumns(initialColumns);
    } else {
      setColumns(DEFAULT_COLUMNS);
    }
  }, [initialColumns]);

  // Debounced persistence of columns
  useEffect(() => {
    if (!isUpdatingFromProps.current && currentBoard && columns) {
      const timeoutId = setTimeout(() => {
        updateBoardColumns(currentBoard, columns);
      }, 500); // 500ms debounce

      return () => clearTimeout(timeoutId);
    }
    isUpdatingFromProps.current = false;
  }, [columns, currentBoard, updateBoardColumns]);

  const handleDragStart = useCallback((columnId, item) => {
    setDraggedItem({ item, sourceColumn: columnId });
  }, []);

  const handleDrop = useCallback((targetColumnId) => {
    if (!draggedItem) return;

    const { item, sourceColumn } = draggedItem;
    const targetColumn = columns[targetColumnId];

    if (!targetColumn) {
      setError('Invalid target column');
      return;
    }

    if (targetColumn.items.length >= targetColumn.limit) {
      setError(`Cannot move task - ${targetColumn.title} has reached its limit of ${targetColumn.limit}`);
      return;
    }

    setColumns(prev => {
      const newColumns = {
        ...prev,
        [sourceColumn]: {
          ...prev[sourceColumn],
          items: prev[sourceColumn].items.filter(task => task.id !== item.id)
        },
        [targetColumnId]: {
          ...prev[targetColumnId],
          items: [...prev[targetColumnId].items, item]
        }
      };
      return newColumns;
    });

    // Log the activity
    logActivity('MOVE_TASK', 
      `Moved task "${item.title}" from ${columns[sourceColumn].title} to ${columns[targetColumnId].title}`
    );

    setDraggedItem(null);
    setError('');
  }, [draggedItem, columns, logActivity]);

  const addTask = useCallback((newTask) => {
    if (!columns.backlog) {
      setError('Backlog column not found');
      return;
    }

    const taskId = `task_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    const task = {
      ...newTask,
      id: taskId,
      createdAt: new Date().toISOString()
    };

    setColumns(prev => ({
      ...prev,
      backlog: {
        ...prev.backlog,
        items: [...prev.backlog.items, task]
      }
    }));

    // Log the activity
    logActivity('CREATE_TASK', 
      `Created new task "${task.title}" in Backlog`
    );

    return taskId;
  }, [columns, logActivity]);

  const updateTask = useCallback((columnId, taskId, updatedTask) => {
    if (!columns[columnId]) {
      setError('Invalid column');
      return;
    }

    const oldTask = columns[columnId].items.find(task => task.id === taskId);
    if (!oldTask) {
      setError('Task not found');
      return;
    }

    setColumns(prev => ({
      ...prev,
      [columnId]: {
        ...prev[columnId],
        items: prev[columnId].items.map(task =>
          task.id === taskId
            ? {
                ...task,
                ...updatedTask,
                lastModified: new Date().toISOString()
              }
            : task
        )
      }
    }));

    // Track and log changes
    const changes = [];
    if (oldTask.title !== updatedTask.title) changes.push('title');
    if (oldTask.description !== updatedTask.description) changes.push('description');
    if (oldTask.priority !== updatedTask.priority) changes.push('priority');
    if (oldTask.dueDate !== updatedTask.dueDate) changes.push('due date');
    if (JSON.stringify(oldTask.assignees) !== JSON.stringify(updatedTask.assignees)) changes.push('assignees');
    if (JSON.stringify(oldTask.tags) !== JSON.stringify(updatedTask.tags)) changes.push('tags');

    if (changes.length > 0) {
      logActivity('UPDATE_TASK',
        `Updated ${changes.join(', ')} of task "${oldTask.title}"`
      );
    }
  }, [columns, logActivity]);

  const deleteTask = useCallback((columnId, taskId) => {
    if (!columns[columnId]) {
      setError('Invalid column');
      return;
    }

    const task = columns[columnId].items.find(task => task.id === taskId);
    if (!task) {
      setError('Task not found');
      return;
    }

    setColumns(prev => ({
      ...prev,
      [columnId]: {
        ...prev[columnId],
        items: prev[columnId].items.filter(t => t.id !== taskId)
      }
    }));

    // Log the activity
    logActivity('DELETE_TASK',
      `Deleted task "${task.title}" from ${columns[columnId].title}`
    );
  }, [columns, logActivity]);

  const moveTask = useCallback((taskId, sourceColumnId, targetColumnId) => {
    if (!columns[sourceColumnId] || !columns[targetColumnId]) {
      setError('Invalid column');
      return;
    }

    const task = columns[sourceColumnId].items.find(t => t.id === taskId);
    if (!task) {
      setError('Task not found');
      return;
    }

    if (columns[targetColumnId].items.length >= columns[targetColumnId].limit) {
      setError(`Cannot move task - ${columns[targetColumnId].title} has reached its limit`);
      return;
    }

    setColumns(prev => ({
      ...prev,
      [sourceColumnId]: {
        ...prev[sourceColumnId],
        items: prev[sourceColumnId].items.filter(t => t.id !== taskId)
      },
      [targetColumnId]: {
        ...prev[targetColumnId],
        items: [...prev[targetColumnId].items, task]
      }
    }));

    // Log the activity
    logActivity('MOVE_TASK',
      `Moved task "${task.title}" from ${columns[sourceColumnId].title} to ${columns[targetColumnId].title}`
    );
  }, [columns, logActivity]);

  const clearColumn = useCallback((columnId) => {
    if (!columns[columnId]) {
      setError('Invalid column');
      return;
    }

    setColumns(prev => ({
      ...prev,
      [columnId]: {
        ...prev[columnId],
        items: []
      }
    }));

    // Log the activity
    logActivity('CLEAR_COLUMN',
      `Cleared all tasks from ${columns[columnId].title}`
    );
  }, [columns, logActivity]);

  return {
    columns,
    error,
    draggedItem,
    handleDragStart,
    handleDrop,
    addTask,
    updateTask,
    deleteTask,
    moveTask,
    clearColumn,
    setError
  };
};

export default useKanban;