// src/pages/ActivityLogPage.jsx
import React, { useState, useMemo } from 'react';
import { Clock, Search, Filter, Trash2 } from 'lucide-react';
import { useActivityLog } from '../contexts/ActivityLogContext';

const ActivityLogPage = () => {
  const { activities, clearActivityLog } = useActivityLog();
  const [searchTerm, setSearchTerm] = useState('');
  const [filterUser, setFilterUser] = useState('all');
  const [filterAction, setFilterAction] = useState('all');

  // Get unique users and actions for filters
  const { users, actions } = useMemo(() => {
    const uniqueUsers = new Set(activities.map(activity => activity.userId));
    const uniqueActions = new Set(activities.map(activity => activity.action));
    return {
      users: Array.from(uniqueUsers),
      actions: Array.from(uniqueActions)
    };
  }, [activities]);

  // Filter activities
  const filteredActivities = useMemo(() => {
    return activities.filter(activity => {
      const matchesSearch = 
        activity.details.toLowerCase().includes(searchTerm.toLowerCase()) ||
        activity.userName.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesUser = filterUser === 'all' || activity.userId === filterUser;
      const matchesAction = filterAction === 'all' || activity.action === filterAction;
      return matchesSearch && matchesUser && matchesAction;
    });
  }, [activities, searchTerm, filterUser, filterAction]);

  // Format relative time
  const getRelativeTime = (timestamp) => {
    const diff = new Date() - new Date(timestamp);
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days === 1 ? '' : 's'} ago`;
    if (hours > 0) return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    if (minutes > 0) return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    return 'Just now';
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Activity Log</h1>
        <button
          onClick={clearActivityLog}
          className="flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
        >
          <Trash2 className="w-4 h-4" />
          Clear Log
        </button>
      </div>

      {/* Filters */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 mb-6">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex-1">
            <div className="relative">
              <Search className="w-5 h-5 absolute left-3 top-2.5 text-gray-400" />
              <input
                type="text"
                placeholder="Search activities..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 p-2 border rounded"
              />
            </div>
          </div>
          <select
            value={filterUser}
            onChange={(e) => setFilterUser(e.target.value)}
            className="p-2 border rounded min-w-[200px]"
          >
            <option value="all">All Users</option>
            {users.map(userId => (
              <option key={userId} value={userId}>
                {activities.find(a => a.userId === userId)?.userName}
              </option>
            ))}
          </select>
          <select
            value={filterAction}
            onChange={(e) => setFilterAction(e.target.value)}
            className="p-2 border rounded min-w-[200px]"
          >
            <option value="all">All Actions</option>
            {actions.map(action => (
              <option key={action} value={action}>
                {action}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Activity List */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        {filteredActivities.length === 0 ? (
          <div className="text-center py-12 text-gray-500">
            <Clock className="w-12 h-12 mx-auto mb-4 opacity-50" />
            <p>No activities found</p>
          </div>
        ) : (
          <div className="divide-y divide-gray-200">
            {filteredActivities.map(activity => (
              <div key={activity.id} className="p-4 hover:bg-gray-50">
                <div className="flex items-start gap-4">
                  <img
                    src={activity.userAvatar || `https://ui-avatars.com/api/?name=${activity.userName}`}
                    alt={activity.userName}
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium text-gray-900">
                        {activity.userName}
                      </p>
                      <span className="text-sm text-gray-500">
                        {getRelativeTime(activity.timestamp)}
                      </span>
                    </div>
                    <p className="mt-1 text-sm text-gray-600">
                      {activity.details}
                    </p>
                    <span className="inline-flex items-center px-2 py-1 mt-2 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                      {activity.action}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityLogPage;