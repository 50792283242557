// pages/LoginPage.jsx
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const LoginPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (window.google?.accounts?.id) {
      window.google.accounts.id.renderButton(
        document.getElementById("signInDiv"),
        { 
          theme: "outline", 
          size: "large",
          type: "standard",
          text: "signin_with",
          width: 280
        }
      );
    }
  }, []);

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user, navigate, from]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to Kanban Board
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Use your Sparrow Google account to sign in
          </p>
        </div>
        <div className="flex justify-center">
          <div id="signInDiv"></div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;