// src/pages/TeamPage.jsx
import React, { useState } from 'react';
import { UserPlus, Mail, X } from 'lucide-react';
import { useBoards } from '../contexts/BoardContext';
import { useAuth } from '../contexts/AuthContext';

const TeamPage = () => {
  const { boards, currentBoard, addBoardMember, removeBoardMember } = useBoards();
  const { user } = useAuth();
  const [newMemberEmail, setNewMemberEmail] = useState('');
  const [error, setError] = useState('');

  const currentBoardData = currentBoard ? boards[currentBoard] : null;

  const handleAddMember = (e) => {
    e.preventDefault();
    if (!newMemberEmail.trim()) {
      setError('Please enter an email address');
      return;
    }

    if (!currentBoard) {
      setError('No board selected');
      return;
    }

    if (currentBoardData.members.includes(newMemberEmail)) {
      setError('Member already exists');
      return;
    }

    addBoardMember(currentBoard, newMemberEmail);
    setNewMemberEmail('');
    setError('');
  };

  const handleRemoveMember = (email) => {
    if (currentBoardData.createdBy === email) {
      setError('Cannot remove board creator');
      return;
    }

    if (email === user.email) {
      setError('Cannot remove yourself');
      return;
    }

    removeBoardMember(currentBoard, email);
  };

  if (!currentBoardData) {
    return (
      <div className="p-6">
        <div className="text-center py-8">
          <h2 className="text-xl font-semibold text-gray-600">No board selected</h2>
          <p className="text-gray-500 mt-2">Please select a board to manage team members</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Team Management</h1>

      {/* Add Member Form */}
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 mb-6">
        <h2 className="text-lg font-semibold mb-4">Add Team Member</h2>
        <form onSubmit={handleAddMember} className="flex gap-4">
          <div className="flex-1">
            <div className="relative">
              <Mail className="w-5 h-5 absolute left-3 top-2.5 text-gray-400" />
              <input
                type="email"
                value={newMemberEmail}
                onChange={(e) => setNewMemberEmail(e.target.value)}
                placeholder="Enter email address"
                className="w-full pl-10 p-2 border rounded"
              />
            </div>
            {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center gap-2"
          >
            <UserPlus className="w-4 h-4" />
            Add Member
          </button>
        </form>
      </div>

      {/* Team Members List */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200">
        <h2 className="text-lg font-semibold p-6 border-b border-gray-200">
          Team Members ({currentBoardData.members.length})
        </h2>
        <div className="divide-y divide-gray-200">
          {currentBoardData.members.map((member) => (
            <div key={member} className="p-4 flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center text-white text-lg font-semibold">
                  {member[0].toUpperCase()}
                </div>
                <div>
                  <div className="font-medium">{member.split('@')[0]}</div>
                  <div className="text-sm text-gray-500">{member}</div>
                </div>
                {member === currentBoardData.createdBy && (
                  <span className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">
                    Creator
                  </span>
                )}
              </div>
              {member !== currentBoardData.createdBy && (
                <button
                  onClick={() => handleRemoveMember(member)}
                  className="text-gray-400 hover:text-red-500"
                >
                  <X className="w-5 h-5" />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamPage;