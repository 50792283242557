// contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadGoogleScript = () => {
      // Load Google's OAuth script
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
          auto_select: false,
          cancel_on_tap_outside: true
        });

        setLoading(false);
      };

      return () => {
        document.head.removeChild(script);
      };
    };

    loadGoogleScript();
  }, []);

  const handleCredentialResponse = (response) => {
    if (response.credential) {
      const decoded = jwtDecode(response.credential);
      console.log('Decoded token:', decoded); // For debugging

      setUser({
        email: decoded.email,
        name: decoded.name,
        picture: decoded.picture,
        accessToken: response.credential
      });
    }
  };

  const login = () => {
    if (window.google?.accounts?.id) {
      window.google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          console.log('Error displaying Google Sign In', notification);
          // Handle error or retry
        }
      });
    }
  };

  const logout = () => {
    if (window.google?.accounts?.id) {
      window.google.accounts.id.revoke(user?.email, () => {
        setUser(null);
      });
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};