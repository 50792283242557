// src/components/BoardSelector.jsx
import React, { useState } from 'react';
import { Plus, Users, X } from 'lucide-react';
import { useBoards } from '../contexts/BoardContext';
import { useAuth } from '../contexts/AuthContext';

const BoardSelector = () => {
  const { boards, currentBoard, setCurrentBoard, createBoard, loading, error } = useBoards();
  const { user } = useAuth();
  const [showNewBoardModal, setShowNewBoardModal] = useState(false);
  const [newBoardName, setNewBoardName] = useState('');
  const [newBoardDescription, setNewBoardDescription] = useState('');
  const [createError, setCreateError] = useState('');

  // Safely access boards data
  const boardsArray = boards ? Object.entries(boards) : [];
  const currentBoardData = currentBoard && boards ? boards[currentBoard] : null;

  const handleCreateBoard = async (e) => {
    e.preventDefault();
    setCreateError('');

    if (!newBoardName.trim()) {
      setCreateError('Board name is required');
      return;
    }

    try {
      const result = await createBoard(newBoardName.trim(), newBoardDescription.trim());
      if (result) {
        setNewBoardName('');
        setNewBoardDescription('');
        setShowNewBoardModal(false);
      } else {
        setCreateError('Failed to create board');
      }
    } catch (error) {
      setCreateError(error.message || 'Failed to create board');
    }
  };

  if (loading) {
    return (
      <div className="mb-8">
        <div className="flex items-center justify-center p-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="mb-8">
      {/* Error Display */}
      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
          {error}
        </div>
      )}

      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-4">
          <select
            value={currentBoard || ''}
            onChange={(e) => setCurrentBoard(e.target.value)}
            className="p-2 border rounded-lg min-w-48 bg-white dark:bg-gray-800 
              text-gray-900 dark:text-gray-100 border-gray-200 dark:border-gray-700
              focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600"
          >
            <option value="" disabled>
              {boardsArray.length === 0 ? 'No boards available' : 'Select a board'}
            </option>
            {boardsArray.map(([id, board]) => (
              <option key={id} value={id}>
                {board.name}
              </option>
            ))}
          </select>
          
          <button
            onClick={() => setShowNewBoardModal(true)}
            className="bg-blue-500 text-white px-3 py-2 rounded-lg hover:bg-blue-600 
              flex items-center gap-2 transition-colors duration-200"
          >
            <Plus className="w-4 h-4" />
            New Board
          </button>
        </div>

        {currentBoardData && currentBoardData.members && (
          <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
            <Users className="w-4 h-4" />
            <span>{currentBoardData.members.length} members</span>
          </div>
        )}
      </div>

      {/* New Board Modal */}
      {showNewBoardModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-96 max-w-[90vw]">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                Create New Board
              </h3>
              <button
                onClick={() => setShowNewBoardModal(false)}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 
                  dark:hover:text-gray-200 transition-colors duration-200"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            {createError && (
              <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/20 border border-red-200 
                dark:border-red-800 rounded-lg text-red-600 dark:text-red-400 text-sm">
                {createError}
              </div>
            )}

            <form onSubmit={handleCreateBoard} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Board Name
                </label>
                <input
                  type="text"
                  value={newBoardName}
                  onChange={(e) => setNewBoardName(e.target.value)}
                  className="w-full p-2 border rounded bg-white dark:bg-gray-700 
                    text-gray-900 dark:text-gray-100 border-gray-200 dark:border-gray-600
                    focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600"
                  placeholder="Enter board name"
                  maxLength={50}
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Description
                </label>
                <textarea
                  value={newBoardDescription}
                  onChange={(e) => setNewBoardDescription(e.target.value)}
                  className="w-full p-2 border rounded bg-white dark:bg-gray-700 
                    text-gray-900 dark:text-gray-100 border-gray-200 dark:border-gray-600
                    focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600"
                  placeholder="Enter board description"
                  rows="3"
                  maxLength={200}
                />
              </div>

              <div className="flex justify-end gap-2 pt-4">
                <button
                  type="button"
                  onClick={() => setShowNewBoardModal(false)}
                  className="px-4 py-2 text-gray-600 dark:text-gray-400 hover:text-gray-800 
                    dark:hover:text-gray-200 transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 
                    transition-colors duration-200 disabled:opacity-50 
                    disabled:cursor-not-allowed"
                  disabled={!newBoardName.trim() || loading}
                >
                  {loading ? (
                    <span className="flex items-center gap-2">
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                      Creating...
                    </span>
                  ) : (
                    'Create Board'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoardSelector;