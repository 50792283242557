// src/components/Sidebar.jsx
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, BarChart2, Users, Calendar, Settings, ActivitySquare } from 'lucide-react';
import { useBoards } from '../contexts/BoardContext';

const Sidebar = ({ isOpen }) => {
  const { currentBoard, boards } = useBoards();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Safely access currentBoardData with null check
  const currentBoardData = currentBoard && boards ? boards[currentBoard] : null;

  const navigation = [
    { path: '/', icon: Layout, label: 'Boards' },
    { path: '/analytics', icon: BarChart2, label: 'Analytics' },
    { path: '/team', icon: Users, label: 'Team' },
    { path: '/calendar', icon: Calendar, label: 'Calendar' },
    { path: '/activity', icon: ActivitySquare, label: 'Activity Log' }
  ];

  return (
    <aside className={`fixed left-0 top-16 h-full bg-white dark:bg-dark-card border-r 
      border-gray-200 dark:border-gray-700 transition-all duration-300 z-20 
      ${isOpen ? 'w-64' : 'w-0'} overflow-hidden`}>
      <div className="p-4">
        {/* Quick Stats */}
        {currentBoardData && (
          <div className="mb-6">
            <h2 className="text-sm font-semibold text-gray-500 dark:text-gray-400 mb-2">
              Quick Stats
            </h2>
            <div className="space-y-2">
              <div className="bg-blue-50 dark:bg-blue-900/20 p-3 rounded-lg">
                <div className="text-sm text-gray-600 dark:text-gray-300">Total Tasks</div>
                <div className="text-xl font-semibold text-blue-600 dark:text-blue-400">
                  {Object.values(currentBoardData.columns || {}).reduce(
                    (acc, col) => acc + (col.items?.length || 0),
                    0
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Navigation */}
        <nav className="space-y-1">
          {navigation.map((item) => (
            <button
              key={item.path}
              onClick={() => navigate(item.path)}
              className={`flex items-center gap-2 w-full p-2 rounded-md transition-colors
                ${location.pathname === item.path
                  ? 'bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400'
                  : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800'
                }`}
            >
              <item.icon className="w-5 h-5" />
              <span>{item.label}</span>
            </button>
          ))}
        </nav>

        {/* Board Members */}
        {currentBoardData && currentBoardData.members && (
          <div className="mt-8">
            <h2 className="text-sm font-semibold text-gray-500 dark:text-gray-400 mb-2">
              Board Members
            </h2>
            <div className="flex flex-wrap gap-2">
              {currentBoardData.members.map((member) => (
                <div
                  key={member}
                  className="flex items-center gap-2 bg-gray-100 dark:bg-gray-800 
                    px-3 py-2 rounded-full"
                >
                  <div className="w-6 h-6 rounded-full bg-blue-500 flex items-center 
                    justify-center text-white text-sm">
                    {member[0].toUpperCase()}
                  </div>
                  <span className="text-sm text-gray-600 dark:text-gray-300">
                    {member.split('@')[0]}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;