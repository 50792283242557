// src/contexts/BoardContext.js
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';

const BoardContext = createContext(null);

// Default board structure with empty columns
const DEFAULT_BOARD = {
  columns: {
    backlog: { title: 'Backlog', items: [], limit: 15 },
    inProgress: { title: 'In Progress', items: [], limit: 8 },
    review: { title: 'Review', items: [], limit: 8 },
    done: { title: 'Done', items: [], limit: 15 }
  }
};

const API_BASE_URL = process.env.REACT_APP_API_URL;

if (!API_BASE_URL) {
    console.error('API URL not configured. Please check your .env file.');
}

const handleResponse = async (response) => {
  const text = await response.text();
  console.log('Raw response:', text);
  
  try {
    const data = JSON.parse(text);
    if (!response.ok) {
      throw new Error(data.error || 'API request failed');
    }
    return data;
  } catch (error) {
    console.error('Error parsing response:', error);
    throw new Error('Invalid response from server');
  }
};

export const BoardProvider = ({ children }) => {
  const [boards, setBoards] = useState({});
  const [currentBoard, setCurrentBoard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  // Load user's boards
  const loadUserBoards = useCallback(async () => {
    if (!user?.email) {
      setLoading(false);
      return;
    }

    try {
      console.log('Loading boards for user:', user.email);
      console.log('API URL:', `${API_BASE_URL}/boards?user=${encodeURIComponent(user.email)}`);
      
      const response = await fetch(
        `${API_BASE_URL}/boards?user=${encodeURIComponent(user.email)}`,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('Response status:', response.status);
      const data = await handleResponse(response);
      console.log('Parsed board data:', data);

      if (!data.success) {
        throw new Error(data.error || 'Failed to load boards');
      }

      setBoards(data.boards || {});
      
      // Set first board as current if none selected
      if (!currentBoard && Object.keys(data.boards || {}).length > 0) {
        setCurrentBoard(Object.keys(data.boards)[0]);
      }
    } catch (error) {
      console.error('Error loading boards:', error);
      setError('Failed to load boards: ' + error.message);
      setBoards({});
    } finally {
      setLoading(false);
    }
  }, [user?.email, currentBoard]);

  // Load boards when auth state changes
  useEffect(() => {
    loadUserBoards();
  }, [user?.email, loadUserBoards]);

  // Create a new board
  const createBoard = async (name, description = '') => {
    if (!user?.email) return null;

    try {
      const boardId = `board_${Date.now()}`;
      const newBoard = {
        id: boardId,
        name,
        description,
        createdBy: user.email,
        createdAt: new Date().toISOString(),
        lastModified: new Date().toISOString(),
        members: [user.email],
        columns: DEFAULT_BOARD.columns
      };

      console.log('Creating board with data:', newBoard);
      console.log('API URL:', `${API_BASE_URL}/boards`);

      const response = await fetch(`${API_BASE_URL}/boards`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newBoard)
      });

      const data = await handleResponse(response);

      if (!data.success) {
        throw new Error(data.error || 'Failed to create board');
      }

      // Update local state
      setBoards(prev => ({
        ...prev,
        [boardId]: newBoard
      }));
      
      setCurrentBoard(boardId);
      setError(null);
      return boardId;

    } catch (error) {
      console.error('Error creating board:', error);
      setError('Failed to create board: ' + error.message);
      return null;
    }
  };

  // Update board columns
  const updateBoardColumns = async (boardId, newColumns) => {
    if (!user?.email) return;

    try {
      const response = await fetch(`${API_BASE_URL}/boards/${boardId}/columns`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          columns: newColumns,
          updatedBy: user.email
        })
      });

      const data = await handleResponse(response);

      if (!data.success) {
        throw new Error(data.error || 'Failed to update columns');
      }

      const updatedBoard = {
        ...boards[boardId],
        columns: newColumns,
        lastModified: new Date().toISOString()
      };

      setBoards(prev => ({
        ...prev,
        [boardId]: updatedBoard
      }));
      
      setError(null);
    } catch (error) {
      console.error('Error updating columns:', error);
      setError('Failed to update columns: ' + error.message);
    }
  };

  // Add a member to a board
  const addBoardMember = async (boardId, newMemberEmail) => {
    if (!user?.email || !boardId || !newMemberEmail) {
      setError('Missing required parameters');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/boards/${boardId}/members`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: newMemberEmail,
          addedBy: user.email
        })
      });

      const data = await handleResponse(response);

      if (!data.success) {
        throw new Error(data.error || 'Failed to add member');
      }

      const board = boards[boardId];
      const updatedBoard = {
        ...board,
        members: [...board.members, newMemberEmail],
        lastModified: new Date().toISOString()
      };

      setBoards(prev => ({
        ...prev,
        [boardId]: updatedBoard
      }));
      
      setError(null);
    } catch (error) {
      console.error('Error adding member:', error);
      setError('Failed to add member: ' + error.message);
    }
  };

  // Remove a member from a board
  const removeBoardMember = async (boardId, memberEmail) => {
    if (!user?.email) return;

    try {
      const board = boards[boardId];
      if (!board) {
        throw new Error('Board not found');
      }

      if (board.createdBy === memberEmail) {
        throw new Error('Cannot remove board creator');
      }

      const response = await fetch(`${API_BASE_URL}/boards/${boardId}/members/${memberEmail}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });

      const data = await handleResponse(response);

      if (!data.success) {
        throw new Error(data.error || 'Failed to remove member');
      }

      if (memberEmail === user.email) {
        // Remove board from view if current user was removed
        setBoards(prev => {
          const newBoards = { ...prev };
          delete newBoards[boardId];
          return newBoards;
        });

        if (currentBoard === boardId) {
          const remainingBoards = Object.keys(boards).filter(id => id !== boardId);
          setCurrentBoard(remainingBoards.length > 0 ? remainingBoards[0] : null);
        }
      } else {
        const updatedBoard = {
          ...board,
          members: board.members.filter(email => email !== memberEmail),
          lastModified: new Date().toISOString()
        };

        setBoards(prev => ({
          ...prev,
          [boardId]: updatedBoard
        }));
      }
      
      setError(null);
    } catch (error) {
      console.error('Error removing member:', error);
      setError('Failed to remove member: ' + error.message);
    }
  };

  // Delete a board
  const deleteBoard = async (boardId) => {
    if (!user?.email) return;

    try {
      const board = boards[boardId];
      if (!board) {
        throw new Error('Board not found');
      }

      if (board.createdBy !== user.email) {
        throw new Error('Only creator can delete board');
      }

      const response = await fetch(`${API_BASE_URL}/boards/${boardId}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });

      const data = await handleResponse(response);

      if (!data.success) {
        throw new Error(data.error || 'Failed to delete board');
      }

      setBoards(prev => {
        const newBoards = { ...prev };
        delete newBoards[boardId];
        return newBoards;
      });

      if (currentBoard === boardId) {
        const remainingBoards = Object.keys(boards).filter(id => id !== boardId);
        setCurrentBoard(remainingBoards.length > 0 ? remainingBoards[0] : null);
      }
      
      setError(null);
    } catch (error) {
      console.error('Error deleting board:', error);
      setError('Failed to delete board: ' + error.message);
    }
  };

  // Get all boards accessible to current user
  const getUserBoards = useCallback(() => {
    if (!user?.email) return [];
    
    return Object.entries(boards)
      .filter(([_, board]) => board.members.includes(user.email))
      .map(([id, board]) => ({
        id,
        ...board
      }));
  }, [boards, user?.email]);

  const value = {
    boards,
    currentBoard,
    setCurrentBoard,
    createBoard,
    deleteBoard,
    updateBoardColumns,
    addBoardMember,
    removeBoardMember,
    getUserBoards,
    loading,
    error,
    reloadBoards: loadUserBoards  // Added for manual reload
  };

  return (
    <BoardContext.Provider value={value}>
      {children}
    </BoardContext.Provider>
  );
};

export const useBoards = () => {
  const context = useContext(BoardContext);
  if (!context) {
    throw new Error('useBoards must be used within a BoardProvider');
  }
  return context;
};

export default BoardProvider;