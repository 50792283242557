// src/components/KanbanBoard.jsx
import React, { useState, useMemo } from 'react';
import { Plus, Search, AlertCircle } from 'lucide-react';
import { Alert, AlertTitle, AlertDescription } from './ui/alert';
import TaskCard from './TaskCard';
import TaskModal from './TaskModal';
import BoardSelector from './BoardSelector';
import useKanban from '../hooks/useKanban';
import { useBoards } from '../contexts/BoardContext';
import { useAuth } from '../contexts/AuthContext';
import APIDebugger from './APIDebugger';

// Add this before the closing div in BoardSelector:
{process.env.NODE_ENV === 'development' && <APIDebugger />}

const KanbanBoard = () => {
  const { boards, currentBoard } = useBoards();
  const { user } = useAuth();
  
  // Get current board data or use default empty board
  const currentBoardData = currentBoard ? boards[currentBoard] : null;
  
  // Custom hook for kanban logic
  const {
    columns,
    error,
    handleDragStart,
    handleDrop,
    addTask,
    updateTask,
    deleteTask,
    setError
  } = useKanban(currentBoardData?.columns);

  // UI state
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterPriority, setFilterPriority] = useState('all');
  const [filterAssignee, setFilterAssignee] = useState('all');

  // Memoized filter function
  const filterTasks = useMemo(() => (tasks) => {
    return tasks.filter(task => {
      const matchesSearch = task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          task.description.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesPriority = filterPriority === 'all' || task.priority === filterPriority;
      const matchesAssignee = filterAssignee === 'all' || 
                             (task.assignees && task.assignees.includes(filterAssignee));
      return matchesSearch && matchesPriority && matchesAssignee;
    });
  }, [searchTerm, filterPriority, filterAssignee]);

  // Event handlers
  const handleEditTask = (columnId, taskId) => {
    const task = columns[columnId].items.find(task => task.id === taskId);
    setEditingTask({ ...task, columnId });
    setShowTaskModal(true);
  };

  const handleTaskSubmit = (taskData) => {
    if (editingTask) {
      updateTask(editingTask.columnId, editingTask.id, taskData);
    } else {
      addTask({
        ...taskData,
        createdBy: user.email,
        createdAt: new Date().toISOString()
      });
    }
    setShowTaskModal(false);
    setEditingTask(null);
  };

  const handleModalClose = () => {
    setShowTaskModal(false);
    setEditingTask(null);
    setError('');
  };

  return (
    <div className="p-6 max-w-6xl mx-auto">
      {/* Board Selector */}
      <BoardSelector />
      
      {!currentBoard || !currentBoardData ? (
        <div className="text-center py-8">
          <h2 className="text-xl font-semibold text-gray-600 dark:text-gray-300">No board selected</h2>
          <p className="text-gray-500 dark:text-gray-400 mt-2">Please select or create a board to get started</p>
        </div>
      ) : (
        <>
          {/* Header */}
          <div className="flex justify-between items-center mb-6">
            <div>
              <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">{currentBoardData.name}</h1>
              {currentBoardData.description && (
                <p className="text-gray-600 dark:text-gray-300 mt-1">{currentBoardData.description}</p>
              )}
            </div>
            <button
              onClick={() => setShowTaskModal(true)}
              className="bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 
                text-white px-4 py-2 rounded transition-colors duration-200 flex items-center gap-2"
            >
              <Plus className="w-4 h-4" />
              New Task
            </button>
          </div>

          {/* Search and Filters */}
          <div className="mb-6 p-4 bg-white dark:bg-dark-card rounded-lg shadow-sm border 
            border-gray-200 dark:border-gray-700 transition-colors duration-200">
            <div className="flex gap-4">
              <div className="flex-1">
                <div className="relative">
                  <Search className="w-5 h-5 absolute left-3 top-2.5 text-gray-400 dark:text-gray-500" />
                  <input
                    type="text"
                    placeholder="Search tasks..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full pl-10 p-2 border rounded bg-white dark:bg-gray-800 
                      text-gray-900 dark:text-gray-100 border-gray-200 dark:border-gray-700
                      focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 transition-colors duration-200"
                  />
                </div>
              </div>
              <select
                value={filterPriority}
                onChange={(e) => setFilterPriority(e.target.value)}
                className="p-2 border rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 
                  border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-blue-500 
                  dark:focus:ring-blue-600 transition-colors duration-200"
              >
                <option value="all">All Priorities</option>
                <option value="low">Low Priority</option>
                <option value="medium">Medium Priority</option>
                <option value="high">High Priority</option>
              </select>
              <select
                value={filterAssignee}
                onChange={(e) => setFilterAssignee(e.target.value)}
                className="p-2 border rounded min-w-[150px] bg-white dark:bg-gray-800 
                  text-gray-900 dark:text-gray-100 border-gray-200 dark:border-gray-700
                  focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 transition-colors duration-200"
              >
                <option value="all">All Assignees</option>
                {currentBoardData.members?.map(member => (
                  <option key={member} value={member}>
                    {member.split('@')[0]}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Error Message */}
          {error && (
            <Alert variant="destructive" className="mb-6">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {/* Task Modal */}
          {showTaskModal && (
            <TaskModal
              task={editingTask}
              onSubmit={handleTaskSubmit}
              onClose={handleModalClose}
            />
          )}

          {/* Kanban Board */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {Object.entries(columns).map(([columnId, column]) => (
              <div
                key={columnId}
                className="bg-gray-100 dark:bg-gray-800/50 p-4 rounded-lg transition-colors duration-200"
                onDrop={() => handleDrop(columnId)}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className="flex justify-between items-center mb-4">
                  <h2 className="font-semibold text-gray-900 dark:text-gray-100">{column.title}</h2>
                  <span className={`text-sm ${
                    column.items.length >= column.limit 
                      ? 'text-red-500 dark:text-red-400 font-medium' 
                      : 'text-gray-500 dark:text-gray-400'
                  }`}>
                    {column.items.length}/{column.limit}
                  </span>
                </div>
                
                <div className="space-y-2 min-h-[200px]">
                  {filterTasks(column.items).map(task => (
                    <TaskCard
                      key={task.id}
                      task={task}
                      columnId={columnId}
                      onDragStart={handleDragStart}
                      onEdit={handleEditTask}
                      onDelete={deleteTask}
                    />
                  ))}

                  {/* Empty State */}
                  {filterTasks(column.items).length === 0 && (
                    <div className="text-center py-8 text-gray-400 dark:text-gray-500">
                      <div className="text-sm">No tasks</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default KanbanBoard;