// src/pages/ProfilePage.jsx
import React, { useState } from 'react';
import { 
  User, 
  Mail, 
  Calendar, 
  Bell, 
  Globe, 
  Moon,
  Sun,
  Sliders,
  CheckCircle 
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useBoards } from '../contexts/BoardContext';

const ProfilePage = () => {
  const { user } = useAuth();
  const { boards } = useBoards();
  const [theme, setTheme] = useState('light');
  const [notifications, setNotifications] = useState({
    taskAssigned: true,
    taskDue: true,
    boardInvites: true,
    taskComments: false
  });
  const [showSavedMessage, setShowSavedMessage] = useState(false);

  // Get user's boards statistics
  const userStats = React.useMemo(() => {
    let totalTasks = 0;
    let completedTasks = 0;
    let totalBoards = 0;

    Object.values(boards).forEach(board => {
      if (board.members.includes(user.email)) {
        totalBoards++;
        Object.values(board.columns).forEach(column => {
          const userTasks = column.items.filter(task => 
            task.assignees?.includes(user.email)
          );
          totalTasks += userTasks.length;
          if (column.title.toLowerCase() === 'done') {
            completedTasks += userTasks.length;
          }
        });
      }
    });

    return {
      totalBoards,
      totalTasks,
      completedTasks,
      completionRate: totalTasks > 0 
        ? ((completedTasks / totalTasks) * 100).toFixed(1) 
        : 0
    };
  }, [boards, user.email]);

  const handleNotificationChange = (key) => {
    setNotifications(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
    showSavedToast();
  };

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    showSavedToast();
  };

  const showSavedToast = () => {
    setShowSavedMessage(true);
    setTimeout(() => setShowSavedMessage(false), 2000);
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Profile Settings</h1>

      {/* Profile Section */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-6">
        <div className="flex items-start gap-6">
          <div className="relative">
            <img
              src={user?.picture || 'https://via.placeholder.com/100'}
              alt={user?.name}
              className="w-24 h-24 rounded-full"
            />
            <button className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600">
              <User className="w-4 h-4" />
            </button>
          </div>
          <div className="flex-1">
            <h2 className="text-xl font-semibold">{user?.name}</h2>
            <div className="flex items-center gap-2 text-gray-600 mt-1">
              <Mail className="w-4 h-4" />
              <span>{user?.email}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600 mt-1">
              <Calendar className="w-4 h-4" />
              <span>Joined {new Date().toLocaleDateString()}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Statistics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
          <div className="text-sm text-gray-500">Total Boards</div>
          <div className="text-2xl font-semibold mt-1">{userStats.totalBoards}</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
          <div className="text-sm text-gray-500">Total Tasks</div>
          <div className="text-2xl font-semibold mt-1">{userStats.totalTasks}</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
          <div className="text-sm text-gray-500">Completed Tasks</div>
          <div className="text-2xl font-semibold mt-1">{userStats.completedTasks}</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
          <div className="text-sm text-gray-500">Completion Rate</div>
          <div className="text-2xl font-semibold mt-1">{userStats.completionRate}%</div>
        </div>
      </div>

      {/* Preferences */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-6">
        <div className="flex items-center gap-2 mb-4">
          <Sliders className="w-5 h-5 text-gray-500" />
          <h2 className="text-lg font-semibold">Preferences</h2>
        </div>

        {/* Theme Selection */}
        <div className="mb-6">
          <h3 className="text-sm font-medium text-gray-700 mb-3">Theme</h3>
          <div className="flex gap-4">
            <button
              onClick={() => handleThemeChange('light')}
              className={`flex items-center gap-2 p-3 rounded-lg border ${
                theme === 'light' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'
              }`}
            >
              <Sun className="w-5 h-5" />
              <span>Light</span>
            </button>
            <button
              onClick={() => handleThemeChange('dark')}
              className={`flex items-center gap-2 p-3 rounded-lg border ${
                theme === 'dark' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'
              }`}
            >
              <Moon className="w-5 h-5" />
              <span>Dark</span>
            </button>
            <button
              onClick={() => handleThemeChange('system')}
              className={`flex items-center gap-2 p-3 rounded-lg border ${
                theme === 'system' ? 'border-blue-500 bg-blue-50' : 'border-gray-200'
              }`}
            >
              <Globe className="w-5 h-5" />
              <span>System</span>
            </button>
          </div>
        </div>

        {/* Notification Settings */}
        <div>
          <h3 className="text-sm font-medium text-gray-700 mb-3">Notifications</h3>
          <div className="space-y-3">
            {Object.entries(notifications).map(([key, enabled]) => (
              <div key={key} className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Bell className="w-5 h-5 text-gray-400" />
                  <span className="text-gray-700">
                    {key.replace(/([A-Z])/g, ' $1').split(' ').map(word => 
                      word.charAt(0).toUpperCase() + word.slice(1)
                    ).join(' ')}
                  </span>
                </div>
                <button
                  onClick={() => handleNotificationChange(key)}
                  className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors
                    ${enabled ? 'bg-blue-500' : 'bg-gray-200'}`}
                >
                  <span
                    className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform
                      ${enabled ? 'translate-x-6' : 'translate-x-1'}`}
                  />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Saved Message Toast */}
      {showSavedMessage && (
        <div className="fixed bottom-4 right-4 flex items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg">
          <CheckCircle className="w-5 h-5" />
          <span>Settings saved!</span>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;