// src/contexts/ActivityLogContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';
import { useAuth } from './AuthContext';

const STORAGE_KEY = 'kanban_activity_log';
const ActivityLogContext = createContext(null);

export const ActivityLogProvider = ({ children }) => {
  const { user } = useAuth();
  const [activities, setActivities] = useState(() => {
    try {
      const savedActivities = localStorage.getItem(STORAGE_KEY);
      return savedActivities ? JSON.parse(savedActivities) : [];
    } catch (error) {
      console.error('Error loading activity log:', error);
      return [];
    }
  });

  const logActivity = useCallback((action, details) => {
    if (!user) return;

    const newActivity = {
      id: `activity_${Date.now()}`,
      timestamp: new Date().toISOString(),
      userId: user.email,
      userName: user.name,
      userAvatar: user.picture,
      action,
      details,
    };

    setActivities(prevActivities => {
      const updatedActivities = [newActivity, ...prevActivities].slice(0, 1000); // Keep last 1000 activities
      localStorage.setItem(STORAGE_KEY, JSON.stringify(updatedActivities));
      return updatedActivities;
    });
  }, [user]);

  const clearActivityLog = useCallback(() => {
    setActivities([]);
    localStorage.removeItem(STORAGE_KEY);
  }, []);

  return (
    <ActivityLogContext.Provider value={{ activities, logActivity, clearActivityLog }}>
      {children}
    </ActivityLogContext.Provider>
  );
};

export const useActivityLog = () => {
  const context = useContext(ActivityLogContext);
  if (!context) {
    throw new Error('useActivityLog must be used within an ActivityLogProvider');
  }
  return context;
};