// src/components/TaskCard.jsx
import React, { memo } from 'react';
import { Clock, Edit2, Trash2, Tag, AlertCircle } from 'lucide-react';

// Helper function to determine priority badge colors based on theme and priority level
const getPriorityColor = (priority) => {
  switch (priority) {
    case 'high':
      return 'bg-red-100 dark:bg-red-900/30 text-red-800 dark:text-red-300';
    case 'medium':
      return 'bg-yellow-100 dark:bg-yellow-900/30 text-yellow-800 dark:text-yellow-300';
    case 'low':
      return 'bg-green-100 dark:bg-green-900/30 text-green-800 dark:text-green-300';
    default:
      return 'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200';
  }
};

// Format the date to a more readable format
const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric'
  });
};

// Check if a task is due within the next 3 days
const isTaskDueSoon = (dueDate) => {
  if (!dueDate) return false;
  const today = new Date();
  const due = new Date(dueDate);
  const diffDays = Math.ceil((due - today) / (1000 * 60 * 60 * 24));
  return diffDays >= 0 && diffDays <= 3;
};

// Safely parse tags from various formats
const parseTags = (tags) => {
  if (Array.isArray(tags)) return tags;
  if (typeof tags === 'string') {
    try {
      const parsed = JSON.parse(tags);
      return Array.isArray(parsed) ? parsed : [];
    } catch {
      return [];
    }
  }
  return [];
};

const TaskCard = memo(({ task, columnId, onDragStart, onEdit, onDelete }) => {
  // Safely destructure task properties with defaults
  const {
    title = '',
    description = '',
    priority = 'medium',
    dueDate,
    assignees = [],
    tags,
  } = task;

  const taskTags = parseTags(tags);

  return (
    <div
      draggable
      onDragStart={() => onDragStart(columnId, task)}
      className="bg-white dark:bg-dark-card p-4 rounded-lg shadow-sm dark:shadow-gray-900/10 
        hover:shadow-md transition-all duration-200 border-l-4 border-transparent 
        hover:border-blue-500 dark:hover:border-blue-400"
    >
      {/* Task Header with Title and Actions */}
      <div className="flex justify-between items-start gap-2">
        <div className="flex-1">
          <h3 className="font-medium text-gray-900 dark:text-gray-100 line-clamp-2">{title}</h3>
          {description && (
            <p className="text-sm text-gray-600 dark:text-gray-400 mt-1 line-clamp-2">{description}</p>
          )}
        </div>
        <div className="flex items-start space-x-1">
          <button 
            onClick={(e) => {
              e.preventDefault();
              onEdit(columnId, task.id);
            }}
            className="p-1 text-gray-400 dark:text-gray-500 hover:text-gray-600 
              dark:hover:text-gray-300 rounded transition-colors duration-200"
            aria-label="Edit task"
          >
            <Edit2 className="w-4 h-4" />
          </button>
          <button 
            onClick={(e) => {
              e.preventDefault();
              onDelete(columnId, task.id);
            }}
            className="p-1 text-gray-400 dark:text-gray-500 hover:text-red-600 
              dark:hover:text-red-400 rounded transition-colors duration-200"
            aria-label="Delete task"
          >
            <Trash2 className="w-4 h-4" />
          </button>
        </div>
      </div>

      {/* Task Metadata (Priority and Due Date) */}
      <div className="mt-3 flex flex-wrap items-center gap-2">
        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium 
          ${getPriorityColor(priority)}`}
        >
          {priority}
        </span>
        
        {dueDate && (
          <span className="inline-flex items-center text-xs text-gray-500 dark:text-gray-400">
            <Clock className="w-3 h-3 mr-1" />
            {formatDate(dueDate)}
          </span>
        )}
      </div>

      {/* Assignees Section */}
      {Array.isArray(assignees) && assignees.length > 0 && (
        <div className="mt-2 flex flex-wrap gap-1">
          {assignees.map((assignee, index) => (
            <span
              key={index}
              className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium
                bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-300"
            >
              {assignee.split('@')[0]}
            </span>
          ))}
        </div>
      )}

      {/* Tags Section */}
      {taskTags.length > 0 && (
        <div className="mt-2 flex flex-wrap gap-1">
          {taskTags.map((tag, index) => (
            <span 
              key={index}
              className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium
                bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300"
            >
              <Tag className="w-3 h-3 mr-1 opacity-50" />
              {tag}
            </span>
          ))}
        </div>
      )}

      {/* Due Soon Warning */}
      {dueDate && isTaskDueSoon(dueDate) && (
        <div className="mt-2 text-xs text-amber-600 dark:text-amber-400 flex items-center">
          <AlertCircle className="w-3 h-3 mr-1" />
          Due soon
        </div>
      )}
    </div>
  );
});

TaskCard.displayName = 'TaskCard';

export default TaskCard;